import { styled, up, css } from '@smooth-ui/core-sc';
import bgImage1x from './images/Landing-Hero.png';
import bgImage2x from './images/Landing-Hero@2x.png';

const HeroBanner = styled.div`
  background-image: url(${bgImage1x});
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 200px;
  margin: 0;

  @media only screen and (min-device-pixel-ratio: 1.5),
    only screen and (min-resolution: 144dpi),
    only screen and (min-resolution: 1.5dppx) {
    background-image: url(${bgImage2x});
  }

  ${up(
    'md',
    css`
      min-height: 300px;
    `
  )};

  ${up(
    'lg',
    css`
      min-height: 400px;
    `
  )};

  ${up(
    'xl',
    css`
      min-height: 709px;
      background-size: contain;
    `
  )};
`;

export default HeroBanner;
