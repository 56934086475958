import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// Style and SEO
import { Col, Grid, Row, styled, up, css } from '@smooth-ui/core-sc';
import Helmet from 'react-helmet';

import { withSettings } from '../../containers/WebApp/SettingsContext';
import PrivateComponent from '_platform/src/utils/PrivateComponent';
import HeroBanner from 'components/CustomComponents/Banners/HeroBanner';
import Ribbon from './images/Ribbon@2x.png';
import Icon1 from './images/Icon1.svg';
import Icon2 from './images/Icon2.svg';
import Icon3 from './images/Icon3.svg';
import Calendar from './images/calendar.svg';

const RibbonOuter = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const RibbonContainer = styled.div`
  background-color: #fff;
  background-image: url(${Ribbon});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  color: #fff;
  font-size: 14px;
  width: 100%;
  max-width: 576px;
  min-height: 62px;
  padding-top: 15px;

  ${up(
    'md',
    css`
      font-size: 18px;
    `
  )};
`;

const BluePanel = styled.div`
  background: #1c355e;
  border-radius: 17px;
  color: #fff;
  padding: 1.5rem 1rem;
  margin-bottom: 10px;

  ${up(
    'md',
    css`
      height: 565px;

      p {
        min-height: 230px;
      }
    `
  )};

  ${up(
    'lg',
    css`
      height: 505px;

      p {
        min-height: 230px;
      }
    `
  )};

  h3 {
    color: #e2c582;
    font-size: 28px;
  }

  a {
    background-color: #a3cf62;
    padding: 10px 20px;
    border-radius: 7px;
    color: #fff;
    text-decoration: none;
    font-size: 12px;
    font-weight: bold;
  }
`;

const HomePage = ({ settings }) => {
  return (
    <div>
      <Helmet>
        {/* <meta name="description" content="Page description" /> */}
      </Helmet>

      <PrivateComponent
        redirectOnError={{
          pathname: settings.settingsApp.loginPagePath || '/login/',
        }}
      >
        <HeroBanner />
        <Grid>
          <Row justifyContent="center">
            <Col xs={12} md={8} className="text--center">
              <h1 className="text-tightline">
                This entertaining season,
                <br />
                entertain and earn cash with the
                <br />
                Patties Food Group Extravaganza!
              </h1>
              <RibbonOuter>
                <RibbonContainer>
                  <strong>Buying Period:</strong> 1st October 2024 - 31st
                  January 2025
                </RibbonContainer>
              </RibbonOuter>
            </Col>
          </Row>
          <Row justifyContent="center" className="spacer">
            <Col xs={12} md={4} lg={3}>
              <BluePanel>
                <img src={Icon1} alt="Purchase" height={80} />
                <h3>Purchase</h3>
                <p>
                  Purchase 15 cartons of participating products including a
                  minimum 2 x 1kg bags of Leader products to earn a $50 Patties
                  Visa Card! For every 15 cartons purchased you are eligible for
                  a $50 reward! So, the more you buy, the more you earn!
                </p>
                <Link to="/how-it-works">VIEW DETAILS</Link>
              </BluePanel>
            </Col>
            <Col xs={12} md={4} lg={3}>
              <BluePanel>
                <img src={Icon2} alt="Upload Invoice" height={80} />
                <h3>Upload Invoice</h3>
                <p>
                  Visit the How it Works page, select the products and quantity
                  purchased and upload a copy of your invoice as proof of
                  purchase to submit your claim.
                </p>
                <Link to="/how-it-works">CLICK HERE</Link>
              </BluePanel>
            </Col>
            <Col xs={12} md={4} lg={3}>
              <BluePanel>
                <img src={Icon3} alt="Track your Progress" height={80} />
                <h3>Track your Progress</h3>
                <p>
                  Visit the My Activity Page to see the status of your claim and
                  how many $50 Visa cards you are eligible for (capped at 4
                  cards per business). At the conclusion of the program, your
                  rewards will be dispatched! Entertain and earn! It’s that
                  easy!
                </p>
                <Link to="/my-activity">VIEW DETAILS</Link>
              </BluePanel>
            </Col>
          </Row>
          <Row justifyContent="center" className="spacer--bottom--large">
            <Col xs={12} md={8} className="text--center">
              <h1>
                <img src={Calendar} alt="Calendar" height={40} />
                {'     '}
                Key dates:
              </h1>
              <p>
                Upload your claims prior to <strong>15th February 2025</strong>
                <br />
                Your rewards will be dispatched in <strong>March 2025</strong>
              </p>
            </Col>
          </Row>
        </Grid>
      </PrivateComponent>
    </div>
  );
};

HomePage.propTypes = {
  settings: PropTypes.object.isRequired,
};

export default withSettings(HomePage);
